<template>
  <div class="px-4">
    <div class="row mx-0 mt-3 mb-3">
      <h1>List Resources</h1>
    </div>
    <div class="cardStyle pt-5">
      <el-table
        header-row-class-name="thead-light"
        v-loading="loaders.resources"
        :data="response.resources.data"
        style="width: 100%"
      >
        <el-table-column label="TITLE" :min-width="20">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ scope.row.bari_resource.title }}</div>
          </template>
        </el-table-column>
        <el-table-column label="CATEGORY" :min-width="15">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ getCategoryName(scope.row.bari_resource) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="SUB CATEGORY" :min-width="15">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ getSubCategoryName(scope.row.bari_resource) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="RESOURCES" :min-width="25">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">
              <a :href="scope.row.bari_resource.link" target="_blank">{{ scope.row.bari_resource.link }}</a>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ENGAGEMENT TIME" :min-width="15">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ scope.row.bari_resource.engagement_time }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" :min-width="10">
          <template slot-scope="scope">
            <div class="d-flex mt-1 toggle">
              <span class="inactive-text pr-3">Off</span>
              <base-switch class="status-toggle"
                           ref="templateSwitcher"
                           on-text="" off-text=""
                           v-model="states.resources.status[scope.$index]"
                           @input="updateResource(scope.row.id,scope.$index)"
              >
              </base-switch>
              <span class="active-text pl-3">On</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pt-5 w-100 d-flex justify-content-end">
        <custom-pagination
          class="pr-5"
          :total="response.resources.total"
          v-model="response.resources.current_page"
          :per-page="parseInt(response.resources.per_page)"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import CustomPagination from "../../Components/Pagination/CustomPagination";
import {Table, TableColumn} from "element-ui";

export default {
  name: "ResourceList",
  components: {
    CustomPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loaders: {
        resources: false,
      },
      states: {
        resources: {
          status: [true, true, true, true, true]
        }
      },
      response: {
        resources: {
          data: [],
          current_page: 1,
          per_page: 10,
          total: 0,
        },
      },
      params: {
        page: 1,
        paginate: 5
      }
    }
  },
  methods: {
    /**
     * Get Category Name
     * @param resource
     * @returns {*|string}
     */
    getCategoryName(resource) {
      return resource?.category?.name ?? ''
    },

    /**
     * Get Sub Category Name
     * @param resource
     * @returns {*|string}
     */
    getSubCategoryName(resource) {
      return resource?.sub_category?.name ?? ''
    },

    /**
     * Change Page and get resources
     * @param page
     */
    changePage(page) {
      this.params.page = page
      this.getResourcesRequest()
    },

    /**
     * Get All Resources
     */
    getResourcesRequest() {
      this.loaders.resources = true
      const config = {
        params: this.params
      }
      axios.get(this.$store.getters.getBaseUrl + '/api/content/resource-manage', config)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.states.resources.status = new Array(response.data.data.data.length)
          for (let i = 0; i < response.data.data.data.length; i++) {
            this.states.resources.status[i] = !!(response.data.data.data[i]['status'])
          }
          this.response.resources = response.data.data
        })
        .catch((error) => console.log(error.response))
        .finally(() => this.loaders.resources = false)
    },

    /**
     * Update Resource
     * @param id
     * @param index
     */
    updateResource(id, index) {
      const data = {
        _method: 'PATCH',
        id: id
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/content/resource-manage/update/${+this.states.resources.status[index]}`, data)
        .then(() => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
        })
        .catch(() => {
          this.$notify.error({
            title: 'Unable to update',
            message: 'Something went wrong please try again later'
          })
          this.$set(this.states.resources.status, index, !this.states.resources.status[index])
        })
    }
  },
  mounted() {
    this.getResourcesRequest()
  }
}
</script>

<style scoped>

</style>
